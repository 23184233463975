import * as React from "react"
import Layout from "../components/layout"
const ObjectivePage = ({ location }) => {
  return (
    <Layout pagePath={location.pathname}>
      <div className="flex flex-col mt-14">
        <h1 className="text-scoringBlue">Project Objectives</h1>
        <p>
          Since the emergence of Cloud Computing and the associated Over-The-Top
          (OTT) valueadded service providers more than a decade ago, the
          architecture of the communication infrastructure − namely the Internet
          and the (mobile) telecommunication infrastructure – keep improving
          with computing, caching and networking services becoming more coupled.
          OTTs are moving from being purely cloud-based to being more
          distributed and residing close to the edge, a concept known to be “Fog
          Computing”. Network operators and telecom vendors advertise the
          “Mobile Edge Computing (MEC)” capabilities they may offer within their
          5G Radio-Access and Core Networks. Lately, the GAFAM (Google, Apple,
          Facebook, Amazon and Microsoft) came into the play as well offering
          what is known as Smart Speakers (Amazon Echo, Apple HomePod and Google
          Home), which can also serve as IoT hubs with “Mist/Skin Computing”
          capabilities. While these have an important influence on the
          underlying network performances, such computing paradigms are still
          loosely coupled with each other and with the underlying communication
          and data storage infrastructures, e.g., even for the forthcoming 5G
          systems. It is expected that a tight coupling of computing platforms
          with the networking infrastructure will be required in post-5G
          networks, so that a large number of distributed and heterogeneous
          devices belonging to different stakeholders communicate and cooperate
          with each other in order to execute services or store data in exchange
          for a reward. This is what we call here the smart collaborative
          computing, caching and networking paradigm. The objective of SCORING
          project is to develop and analyse this new paradigm by targeting the
          following research challenges, which are split into five different
          strata:
        </p>
        <ul className="list-disc ml-10">
          <li>
            <strong className="text-scoringBlue">
              At the computing stratum:
            </strong>{" "}
            Proactive placement of computing services, while taking into account
            users mobility as well as per-computing-node battery status and
            computing load;
          </li>
          <li>
            <strong className="text-scoringBlue">
              At the computing stratum:
            </strong>{" "}
            Proactive placement of stores and optimal caching of
            contents/functions, while taking into account the joint networking
            and computing constraints;
          </li>
          <li>
            <strong className="text-scoringBlue">
              At the software stratum:
            </strong>{" "}
            Efficient management of micro-services in such a multi-tenant
            distributed realm, by exploiting the Information-Centric Networking
            principles to support both name and compute function resolution;
          </li>
          <li>
            <strong className="text-scoringBlue">
              At the networking stratum:
            </strong>{" "}
            Enforcement of dynamic routing policies, using Software Defined
            Networking (SDN), to satisfy the distributed end-user computation
            requirements and their Quality of Experience (QoE);
          </li>
          <li>
            <strong className="text-scoringBlue">
              At the resource management stratum:
            </strong>{" "}
            Design of new network-economic models to support service offering in
            an optimal way, while considering the multi-stakeholder feature of
            the collaborative computing, caching and networking paradigm
            proposed in this project. Smartness will be brought here by using
            adequate mathematical tools used in combination for the design of
            each of the five strata: machine learning (proactive placement
            problems), multi-objective optimization, graph theory and complex
            networks (information-centric design of content and micro-services
            caching) and game theory (network-economics model).
          </li>
        </ul>
        <p>
          Demonstration of the feasibility of the proposed strata on a realistic
          and integrated testbed as well as on an integrated simulation platform
          (based on available open-source network-simulation toolkits), will be
          one of the main goals of the project. The test-bed will be built by
          exploiting different virtualization (VM/Containers) technologies to
          deploy compute and storage functions within a genuine networking
          architecture. Last but not least, all building blocks forming the
          realistic and integrated test-bed, on the one hand, and the integrated
          simulation platform, on the other hand, will be made available to the
          research community at the end of the project as open source software.
        </p>
      </div>
    </Layout>
  )
}

export default ObjectivePage
